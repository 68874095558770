

import { useContext } from "react"
import style from "./index.module.css"
import { PAIRCONTEXT } from "../../context"


export default function NowLover(){

    const PAIRC = useContext(PAIRCONTEXT)
    const pairedSessionId = PAIRC.flow.pairSessionId

    return <div>
        

        <div>{pairedSessionId}</div>

        <div>
            <p>你们于 。。 正式确立情侣关系</p>

        </div>


        <div>
            <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"READY_BE_LOVER"})}>&lt;-</span>
        </div>

    </div>
}