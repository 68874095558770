import { useNavigate, useParams } from "react-router-dom"
import { axios } from "../../axios"
import { useEffect, useState } from "react"
import { Button, Input } from "antd"

import style from './css/post.detail.module.css'
import { localDate, share, toFold } from "../../tools"

export default function TopicPostDetail(){
    const navi = useNavigate()
    const id = useParams().id
    const [post,setPost] = useState({
        description:""
    })
    const [replys,setReplys] = useState({
        list:[],
        total:0,
        page:0
    })
    const [replyToAdd,setReplyToAdd] = useState("")
    const [foldRepyId,setFoldReplyId] = useState(null)
    useEffect(()=>{
        getPostDetail()
        getReplys()
        // eslint-disable-next-line
    },[])

    const [showFullDescription,setShowFullDescription] = useState(false)

    //置灰提交按钮
    const [submitBtnDisable,setSubmitBtnDisable] = useState(false)

    return <div className={style.container}>

        <div className={style.detail}>
            <div className={style.postHeader}>
                <span className={style.node} onClick={()=>navi("/topic/node/detail/"+post.nodeId)}>#{post.node}</span>
                <div className={style.right}>
                    <span className={style.user} 
                        onClick={()=>navi("/user/profile/"+post.user.id)}>{getNicknameOrUsername(post.user)}</span>
                    <span className={style.date}>{localDate(post.createDatetime)}</span>
                </div>
                
            </div>
            <div className={style.starAndUnstar}>
                <span className={style.ss}>
                    <span>回复数{post.replyCount}</span>
                    {post.stared?<span>顶{post.star}</span>:
                    <span className={style.un} onClick={starPost}>顶{post.star}</span>}
                    {post.unstared?<span>踩{post.unstar}</span>:
                    <span className={style.un} onClick={unstarPost}>踩{post.unstar}</span>}
                </span>
                <span className={style.shareAndArchive}>
                    {post.archived?<span className={style.archived} onClick={unarchivePost}>已收藏</span>:
                    <span className={style.unarchived} onClick={archivePost}>收藏</span>}
                    <span className={style.share} onClick={()=>share("/#/topic/post/detail/"+post.id)}>分享</span>
                </span>
            </div>

            <div style={{borderBottom:"1px solid lightgray",margin:"8px 0px"}}></div>

            <div className={style.title}>
                <span>{post.title}</span>
            </div>
            <div style={{borderBottom:"1px solid lightgray",margin:"8px 0px"}}></div>
            <div>
                <div className={style.postDesciption}>
                    <div className={toFold(post.description)&&!showFullDescription?style.descriptionHide:style.description}>{post.description}</div>
                    {toFold(post.description)&&<div>
                        {showFullDescription?<span onClick={()=>setShowFullDescription(false)} style={{fontSize:"0.9em",color:"#ababab"}}>^收起</span>:
                        <span onClick={()=>setShowFullDescription(true)} style={{fontSize:"0.9em",color:"#ababab"}}>...查看全部</span>}
                    </div>}
                </div>
            </div>
 
        </div>

        <div className={style.replys}>
            {replys.list.map(e=><div key={e.id} className={style.reply}>
                <div className={style.replyId}>#{e.id}</div>
                <div className={style.replyHeader}>
                    <span className={style.user} 
                        onClick={()=>navi("/user/profile/"+e.user.id)}>{getNicknameOrUsername(e.user)}</span>
                    <span className={style.date}>{localDate(e.createDatetime)}</span>
                </div>
                <p className={toFold(e.content)&&e.id!==foldRepyId?style.replyContentHide:style.replyContent}><span>{e.content}</span></p>

                {toFold(e.content)&&<>{foldRepyId===e.id?
                    <p className={style.folder} onClick={()=>setFoldReplyId(null)}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>^收起</span>   
                    </p>:<p className={style.folder} onClick={()=>setFoldReplyId(e.id)}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>...查看全部</span>
                    </p>}</>}

                <div className={style.replyFoot}>
                    <span onClick={()=>navi("/topic/post/reply/"+e.id)}>回复{e.replyCount}</span>
                    <span onClick={()=>starReply(e)} className={e.stared?style.active:""}>顶{e.star}</span>
                    <span onClick={()=>unstarReply(e)} className={e.unstared?style.active:""}>踩{e.unstar}</span> 
                    {!e.archived&&<span onClick={()=>archiveReply(e)}>收藏</span>}
                    {e.archived&&<span onClick={()=>unarchiveReply(e)} className={style.active}>取消收藏</span>}
                    <span onClick={()=>share("/#/topic/post/reply/"+e.id)}>分享</span>
                </div>
            </div>)}
        </div>
        <div>
            <div>
                <Input.TextArea autoSize value={replyToAdd} onChange={e=>setReplyToAdd(e.target.value)} />
                <Button disabled={submitBtnDisable?true:false} onClick={()=>{
                    setSubmitBtnDisable(true)
                    addReply()
                }}>回复主题</Button>
            </div>
        </div>
    </div>

    function getPostDetail() {
        axios.get("/api/topic/post",{
            params:{
                id
            }
        })
        .then(({data})=>{
            setPost(data.data)
        }).catch(e=>{})
    }

    function getReplys() {
        axios.get("/api/topic/reply",{
            params:{
                postId:id,
                page:0
            }
        }).then(({data})=>{
            if(data.code===200){
                setReplys({...replys,list:data.data.list,total:data.data.total})
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    function addReply() {
        if(!replyToAdd){
            alert("")
            return
        }
        axios.post("/api/topic/reply",{
            postId:id,
            reply:replyToAdd
        }).then(({data})=>{
            if(data.code===200){
                setReplyToAdd("")
                setSubmitBtnDisable(false)
                getReplys()
            }
        }).catch(err=>{})
    }


    function starPost(){
        axios.post("/api/topic/post/star",{
            postId:id
        }).then(({data})=>{
            if(data.code===200){
                getPostDetail()
            }
        }).catch(err=>{})
    }
    function unstarPost(){
        axios.post("/api/topic/post/unstar",{
            postId:id
        }).then(({data})=>{
            if(data.code===200){
                getPostDetail()
            }
        }).catch(err=>{})
    }

    function archivePost(){
        axios.post("/api/topic/post/archive",{
            postId:id
        }).then(({data})=>{
            if(data.code === 200){
                getPostDetail()
            }
        })
    }
    function unarchivePost(){
        axios.delete("/api/topic/post/archive",{
            params:{
                postId:id
            }
        }).then(({data})=>{
            if(data.code === 200){
                getPostDetail()
            }
        })
    }



    // 
    function getNicknameOrUsername(user){
        if(!user){
            return ""
        }
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }



    // 顶踩收藏评论
    function starReply(e){
        axios.post("/api/topic/reply/star",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }
        }).catch(err=>{})
    }
    function unstarReply(e){
        axios.post("/api/topic/reply/unstar",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }
        }).catch(err=>{})
    }
    function archiveReply(e){
        axios.post("/api/topic/reply/archive",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = true
                    }
                })
                setReplys({...replys,list:tmps})
            } 
        }).catch(err=>{})
    }
    function unarchiveReply(e){
        axios.delete("/api/topic/reply/archive",{
            params:{
                replyId:e.id
            }
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = false
                    }
                })
                setReplys({...replys,list:tmps})
            }    
        }).catch(err=>{})
    }


}