


// 复制url到剪切板

function share(uri){
    //从浏览器获取host
    let host = new URL(window.location.href)
    window.navigator.clipboard.writeText(host.origin+uri)
    .then(()=>{
        alert("链接已复制到剪切板")
    })
}



let checkLogin=()=>{
    let user = localStorage.getItem("info")
    if(!user){
        window.location.href='/#/login'
        return false
    }
    return true   
}

let localDate=(date)=>{
    if(!date){
        return "";
    }
    let tmp = new Date(date)
    if(!tmp){
        return "";
    }
    tmp = new Date(tmp.getTime()-tmp.getTimezoneOffset()*60000);
    let t2 = (tmp.getMonth()+1)+"/"+tmp.getDate()+" "+tmp.getHours()+":"+tmp.getMinutes()
    return t2
}
let localDate2=(date)=>{
    if(!date){
        return "";
    }
    let tmp = new Date(date)
    if(!tmp){
        return "";
    }
    tmp = new Date(tmp.getTime()-tmp.getTimezoneOffset()*60000);
    let t2 = (tmp.getFullYear())+"/"+(tmp.getMonth()+1)+"/"+tmp.getDate()+" "+tmp.getHours()+":"+tmp.getMinutes()
    return t2
}

function isAdmin(){
    let info = localStorage.getItem("info")
    if(!info){
        return false
    }
    info = JSON.parse(info)
    let roles = info.roles
    return roles.includes('ADMIN')
}

// 判断是否折叠
function toFold(text){
    if(text.length>100){
        return true
    }
    //如果换行符超过3个 返回true
    let count = 0
    for(let i=0;i<text.length;i++){
        if(text[i]==='\n'){
            count++
        }
        if(count>3){
            return true
        }
    }
    return false
}

const DB_NAME="demo1"
class DB{
    constructor(){
        let storeName = "profiles";
        const request = indexedDB.open(DB_NAME, 1);
        request.onupgradeneeded = function (event) {
            //获取数据库对象
            const db = event.target.result;
            if(!db.objectStoreNames.contains(storeName)){
                console.log("store不存在")
                db.createObjectStore(storeName)
            }
        }
    }

    add = (storeName,key,value)=>{
        const request = indexedDB.open(DB_NAME, 1);

        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                //获取数据库对象
                const db = event.target.result;
                //获取事务对象
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);

                //先查
                const getResult = store.get(key);
                getResult.onsuccess = (event)=>{
                    let data = event.target.result
                    if(data){
                        console.log("查到了,值是:",data)
                        resolve(data)
                    }else{
                        const addReq = store.add(value, key);
                        addReq.onsuccess = (e)=>{
                            resolve("插入成功")
                        }
                        addReq.onerror = (e)=>{
                            reject("插入失败")
                        }
                    }
                }
            }
        })
    }

    getByKey = (storeName,key)=>{
        const request = indexedDB.open(DB_NAME, 1);

        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                //获取数据库对象
                const db = event.target.result;
                //获取事务对象
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);
                //获取数据
                const result = store.get(key);
                result.onsuccess = function (event) {
                    let data = event.target.result
                    if(data)
                        resolve(data)
                    else
                        reject("no")
                }
                result.onerror = (e)=>{
                    reject("no")
                }
            }
            request.onerror = (e)=>{
                reject("no1")
            }
        })
        
    }

    delete = (storeName,key)=>{
        const request = indexedDB.open(DB_NAME, 1);
        //数据库连接成功
        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                const db = event.target.result;
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);
                //先查是否存在 再删除
                const result = store.get(key);
                result.onsuccess = function (event) {
                    let data = event.target.result
                    if(data){
                        store.delete(key).onsuccess=(e)=>{
                            resolve("删除成功")
                        };
                    }else{
                        resolve("key不存在 无需删除")
                    }
                }
            }
            request.onerror = (e)=>{
                reject(e)
            }
        })
        
    }

    //清除仓库所有
    clear = (storeName)=>{
        const request = indexedDB.open(DB_NAME, 1);
        //数据库连接成功
        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                const db = event.target.result;
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);
                store.clear().onsuccess = function(event){
                    resolve("清除成功")
                }
            }
            request.onerror = (e)=>{
                reject(e)
            }
        })
    }
}


function getNicknameOrUsername(user){
    if(user.nickname){
        return user.nickname
    }
    if(user.username){
        return user.username
    }
    return ""
}

function checkDate(data){
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if(regex.test(data)===false){
        return false
    }
    let tmps =data.split("-")
    let tmpsYear = parseInt(tmps[0])
    let tmpsMonth = parseInt(tmps[1])
    let tmpsDay = parseInt(tmps[2])

    let tmp = new Date(data)
    let year = tmp.getFullYear()
    let month = tmp.getMonth()+1
    let day = tmp.getDate()

    if(year!==tmpsYear || month!==tmpsMonth || day!==tmpsDay){
        return false
    }
    return true
}

export {checkDate,checkLogin,localDate,localDate2,isAdmin,toFold,DB,share,getNicknameOrUsername}