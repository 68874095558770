import { useNavigate } from "react-router-dom";
import Btn from "../../my-com/btn";



import style from "./index.module.css"

export default function About(){
    const navi = useNavigate()
    return <div className={style.container}>
        <p className={style.header}>
            <Btn text="返回" onClick={()=>navi("/user")} />
        </p>
        
        <div>
            <p>人生终归落于平淡，我不喜欢片刻欢愉后空虚袭来的感觉，我想要爱。</p>
        </div>
        <div>
            
        </div>
    </div>
}