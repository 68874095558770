import { useContext, useEffect, useState } from "react";
import { PAIRCONTEXT } from "../../context";

import style from "./index.module.css"
import { axios } from "../../../../../axios";
import { Dislike, Like } from "./like";

export default function ConfirmFace(props){


    const PAIRC = useContext(PAIRCONTEXT)
    const sessionId = PAIRC.flow.pairSessionId

    const [show,setShow] = useState({
        show:false,
        type:'',//like or dislike,
    })

    const [confirmRecord,setConfirmRecord] = useState({})

    useEffect(()=>{
        getConfirmRecord()
        // eslint-disable-next-line
    },[])

    return <div>

        <div>
            <h2 style={{textAlign:"center"}}>外形确认</h2>
        </div>


        <div>
            外形反馈
            双方资料都已经确认过 现在联系方式双方互相可见。
            请及时添加联系方式，互发照片、视频、线下见面的形式确认对方是不是你喜欢的类型。

            每个人喜欢的类型不一样，有人喜欢瘦瘦的，有人喜欢胖胖的，有人喜欢结实的不胖也不瘦的。

            每个人喜欢的类型不一样，如果不合适，请及时反馈“不合适”。单方面看上眼是常见情况，不要怀疑自己。
            保持礼貌，在对话框发一句“不好意思，不是我喜欢的类型”再互删。
        </div>




        {show.show&&<div>
            {show.type==='like'&&<Like sessionId={sessionId} close={()=>{
                setShow({show:false,type:''})
                window.location.reload()
            }} />}   

            {show.type==='dislike' && <Dislike sessionId={sessionId} close={()=>{
                setShow({show:false,type:''})
                window.location.reload()
            }} />}
        </div>}

        
        <div>
            {confirmRecord.mySeeFace && <div>
                <p>我的决定: {confirmRecord.mySeeFace.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <p>{confirmRecord.mySeeFace.comment}</p>
            </div>}

            {confirmRecord.hisSeeFace && <div>
                <p>他的决定: {confirmRecord.hisSeeFace.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <p>{confirmRecord.hisSeeFace.comment}</p>
            </div>}
        </div>
        

        <div className={style.confirmBtns}>
            {/* 务必双方互看后再进行下面操作。请不要出现单方面看后删除的情况。 */}
            {!confirmRecord.mySeeFace && <div>
                <span onClick={()=>setShow({show:true,type:'dislike'})}>不合适</span>
                <span onClick={()=>setShow({show:true,type:'like'})}>继续沟通</span>
            </div>}
        </div>


        <div className={style.preOrNext}>
            <span  className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"SAY_HELLO"})}>&lt;-</span>
            {PAIRC.canToNext('FACE_PAIRING') && 
                <span  className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"READY_BE_LOVER"})}>-&gt;</span>}
        </div>
    </div>


    function getConfirmRecord(){
        axios.get("/api/square/flow/face",{
            params:{
                sessionId
            }
        }).then(({data})=>{
            if(data.code===200){
                setConfirmRecord(data.data)
            }
        }).catch(err=>{console.log(err)})
    }
}