import { Input } from "antd"
import { useState } from "react"


export default function BaseEditor(props){

    const [value,setValue] = useState(props.value)

    return<div>
        <h4>修改{props.title}</h4>
        <div>
            <Input value={value} 
                placeholder={props.ph} onChange={e=>{
                    setValue(e.target.value)
                    props.back(e.target.value)
                }} />
        </div>
    </div>
}