import { Link, useParams } from "react-router-dom"
import { axios } from "../../axios"
import { useEffect, useState } from "react"
import { Button, Input } from "antd"

import style from "./css/node.detail.module.css"
import Alert from "../my-com/alert"

export default function NodeDetail(){

    const id = useParams().id

    useEffect(()=>{
        getNodeDetail(id)
        getPosts()
        // eslint-disable-next-line
    },[])

    const [node,setNode] = useState({})

    const [posts,setPosts] = useState({
        list:[],
        total:0,
        current:0
    })

    //控制提交按钮
    const [submitBtnDisable,setSubmitBtnDisable] = useState(false)

    const [postToAdd,setPostToAdd] = useState({ title:"",description:"" })
    const [showAdd,setShowAdd] = useState(false)
    const [showDescription,setShowDescription] = useState(false)

    return <div className={style.container}>
        <div>
            <h3 className={style.title}>
                # {node.node}
                <span onClick={()=>displyDescription(true)}>?</span>
            </h3>
            <div className={style.btns}>
                <div className={style.addBtn}>
                    <span onClick={()=>setShowAdd(true)}>新话题</span>
                </div>
                <div className={style.archiveBtn}>
                    {node.archived?<span onClick={unarchiveTopic}>取消收藏</span>:
                    <span onClick={archiveTopic}>收藏节点</span>}
                </div>
            </div>
        </div>


        <div className={style.posts}>
            <p>
                <span>最新</span>
                <span>精选</span>
            </p>
            {posts.list.map(e=><div key={e.id} className={style.post}>
                    <div>
                        <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                    </div>
            </div>)}
        </div>

        {showAdd&&<div className={style.addPage}>
            <div className={style.addContainer}>
                <div className={style.addTitleInput}>
                    <Input placeholder="标题" onChange={e=>setPostToAdd({...postToAdd,title:e.target.value}) } />
                </div>

                <div className={style.addContentInput}>
                    <Input.TextArea placeholder="内容"  
                        autoSize
                        onChange={e=>setPostToAdd({...postToAdd,description:e.target.value})}/>
                </div>
  
                

                <div>
                    <Button disabled={submitBtnDisable?true:false} onClick={()=>{
                        setSubmitBtnDisable(true)
                        addPost()
                    }}>提交</Button>
                    <Button onClick={()=>setShowAdd(false)}>取消</Button>
                </div>
            </div>
        </div>}

        {showDescription&&<div className={style.description}>
            <div>
                <div className={style.content}>
                    <div>
                        <span onClick={()=>displyDescription(false)} className={style.closeBtn}>关闭</span>
                    </div>
                    
                    <div>
                        {node.description}
                    </div>
                </div>
            </div>
        </div>}
    </div>

    function displyDescription(v) {
        setShowDescription(v)
        if(v){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
    }


    function getNodeDetail(id) {
        axios.get("/api/topic/node",{
            params:{
                id
            }
        }).then(({data})=>{
            if(data.code===200){
                setNode(data.data)
            }
        }).catch(err=>{})
    }

    function getPosts(){
        axios.get("/api/topic/posts",{
            params:{
                nodeId:id,
                offset:0
            }
        }).then(({data})=>{
            setPosts({...posts,list:data.data.list,total:data.data.total})
        }).catch(err=>{})
    }

    function addPost(){
        if(!postToAdd.title){
            alert("标题不能为空")
            return
        }
        if(postToAdd.title.length>250){
            Alert.alert("标题不能超过250个字符")
            return
        }
        if(!postToAdd.description){
            alert("内容不能为空")
            return
        }
        axios.post("/api/topic/post",{
            ...postToAdd,
            nodeId:id
        }).then(({data})=>{
            if(data.code===200){
                setShowAdd(false)
                getPosts()
                setSubmitBtnDisable(false)
            }
        }).catch(err=>{})
    }

    function archiveTopic(){
        axios.post("/api/topic/node/archive",{
            nodeId:id
        }).then(({data})=>{
            if(data.code===200){
                getNodeDetail(id)
            }
        }).catch(err=>{})
    }
    function unarchiveTopic(){
        axios.delete("/api/topic/node/archive",{
            params:{
                nodeId:id
            }
        }).then(({data})=>{
            if(data.code===200){
                getNodeDetail(id)
            }
        }).catch(err=>{})
    }


}



