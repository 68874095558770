
//成员资格


export default function VIP(){

    return <div>
        成员资格

        为了社区成员、内容质量，社区采用会员制。

        非社区成员不开放寻ta功能，动态和话题功能有限开放。
    </div>
}


