


import style from "./index.module.css"

export default function SquareSetting(){
    return <div className={style.container}>

        <div>
            为什么会想要做这个功能？

            太多快餐式的同性感情，基于社会压力也好、自我认同问题也好，既渴望又恐惧走入一段稳定关系。

            我不喜欢这样。

            如果你也不喜欢这样，欢迎加入浮尘社区开始寻TA。寻一个志同道合的TA，体会生活，感受爱与被爱。
        </div>
        <div>
            <p>如果你已有爱人或固定的亲密关系，请不要开启此功能。点击下方按钮进行关闭。</p>
            <p>关闭后你的资料将会隐藏其他人不再可见同时你也不能再查看别人的资料。</p>
            <span>关闭、打开</span>
        </div>

        <div>
            <p>希望你能在这里找到三观匹配的归宿。</p>
            <p>一个人也能过，但有"你"更好。</p>
        </div>

        <div>
            此功能主要是为了寻找长久的亲密关系，不是约炮工具。不是约炮工具。不是约炮工具。
            如果你得目标不是一段长久稳定的亲密关系，请去其他合适的平台。
        </div>

        <div>
            <p>功能介绍</p>

            <p>1. 确认资料</p>
            <p>2. 确认外形。通过其他方式确认外形是否合适</p>
            <p>3. 试相处一周。外形合适，这时候已经加了联系方式。可以试相处一星期。一周后在此做出相处反馈。双方反馈都合适的话，结成伴侣关系。</p>
            <p>4. 伴侣关系。双方都关闭寻TA功能。</p>
        </div>

        
    </div>
}