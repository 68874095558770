import style from "./index.module.css"
import { useNavigate } from "react-router-dom"
import { PasteKey } from "./paste.key"
import { useState } from "react"


export default function Home(){

    const navi = useNavigate()

    const [show,setShow] = useState({
        enable:false,
        code:0
    })


    return<div className={style.container}>


        {show.enable && <PasteKey close={()=>setShow({...show,enable:false})} />}


        <div style={{fontSize:'0.8em',textAlign:'center'}} >
            <p>寻找，遇见。</p>
        </div>

 

        <div className={style.itemsBg}>
            <div className={style.items}>
                <div className={style.left}> 
                    <div className={style.find} onClick={()=>{
                                navi("/square/find")
                            }}>
                        开始寻他
                    </div>
                    <div className={style.profile} onClick={()=>{
                        navi("/square/profile")
                    }}>
                        我的资料
                    </div>
                    <div onClick={()=>{
                        navi('/square/share')
                    }}>
                        我的密钥
                    </div>

                    <div onClick={()=>setShow({...show,enable:true})}>
                        密钥直达
                    </div>
  
                </div>

                <div className={style.right}>
                    <div>
                        我的爱人
                    </div>
                    <div className={style.record} onClick={()=>{
                        navi("/square/paired")
                    }}>
                        擦肩记录
                    </div>
                    <div onClick={()=>navi("/square/setting")}>
                        必读与设置
                    </div>
                </div>
            </div>
        </div>
        
    </div>




}