import axios from "axios";

const developing=false



const request = axios.create({
    baseURL: developing?"http://localhost:8882":""
})

request.interceptors.request.use(request=>{

    let user = localStorage.getItem("info")
    if(user){
        user = JSON.parse(user)
        request.headers.Authentication=user.token
    }    
    //检查本地有没有token 响应401 则需要跳转到登录页面
    return request
})

request.interceptors.response.use(response=>{

    return response
},error=>{
    let status = error.response.status
    if(status===401 || status===403){
        localStorage.clear()
        localStorage.setItem("preURL",window.location.href)
        window.location.href="/#/login"
    }
    return Promise.reject(error.response)
})

export {request as axios}
