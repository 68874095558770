import { useEffect, useState } from "react"
import { axios } from "../../../axios"

import style from "./css/index.module.css"
import { genderStr, getSexRole } from "./dict"
import Editors from "./editors"
import { useNavigate } from "react-router-dom"
import Alert from "../../my-com/alert"

export default function SquareProfile(){

    const navi = useNavigate()

    useEffect(()=>{
        getProfile()
        // eslint-disable-next-line
    },[])

    const [edit,setEdit] = useState({
        show:false,
        key:"",
        title:"",
        value:'',
        placeholder:""
    })
    const [profile,setProfile] = useState({

    })


    return <div className={style.container}>
        {!edit.show&&<div className={style.profile}>
            <h3>我的资料</h3>
            <div className={style.header}>
                <span>
                    <span className={style.gray}>灰色</span>条目互相匹配后对方可见
                </span>
                <span className={style.btn} onClick={()=>navi("/square/profile/preview")}>预览</span>
            </div> 

            <div className={style.item} 
                onClick={()=>toEdit("name",profile.name)}>
                <span>昵称</span>
                <div>{profile.name}</div>
            </div>

            <div className={style.item} 
                onClick={()=>toEdit("gender",genderStr(profile.gender))}>
                <span>性别</span>
                <div>{genderStr(profile.gender)}</div>
            </div>

            <div className={style.item} onClick={()=>toEdit("age",profile.age)}>
                <span>年龄</span>
                <div>{profile.age}</div>
            </div>

            <div className={[style.item,(profile.hideCode&(1<<17))!==0?style.hide:""].join(" ")}
                onClick={()=>toEdit("birthday",profile.birthday)}>
                <span>生日</span>
                <div>{profile.birthday}</div>
            </div>

            <div className={style.signature} 
                onClick={()=>toEdit("signature",profile.signature)}>
                <span>个性签名</span>
                <div>{profile.signature}</div>
            </div>

            <div className={[style.item,(profile.hideCode&(1<<14))!==0?style.hide:""].join(" ")} 
                onClick={()=>toEdit("sexRole",profile.sexRole)}>
                <span>性角色</span>
                <div>{getSexRole(profile.sexRole)}</div>
            </div>

            <div className={style.item}
                onClick={()=>toEdit("height",profile.height)}>
                <span>身高</span>
                <div>{profile.height}</div>
            </div>
            <div className={style.item}
                onClick={()=>toEdit("weight",profile.weight)}>
                <span>体重</span>
                <div>{profile.weight}</div>
            </div>
            <div className={style.item}
                onClick={()=>toEdit("city",profile.city)}>
                <span>所在城市</span>
                <div>{profile.city}</div>
            </div>
            <div className={[style.item,(profile.hideCode&(1<<10))!==0?style.hide:""].join(" ")}
                onClick={()=>toEdit("hometown",profile.hometown)}>
                <span>家乡</span>
                <div>{profile.hometown}</div>
            </div>

            <div className={style.item}
                onClick={()=>toEdit("job",profile.job)}>
                <span>职业</span>
                <div>{profile.job}</div>
            </div>
            <div className={[style.item,(profile.hideCode&(1<<8))!==0?style.hide:""].join(" ")}
                onClick={()=>toEdit("salary",profile.salary)}>
                <span>年薪</span>
                <div>{profile.salary}</div>
            </div>
            <div className={style.item}
                onClick={()=>toEdit("education",profile.education)}>
                <span>学历</span>
                <div>{profile.education}</div>
            </div>

            <div className={[style.item,(profile.hideCode&(1<<6))!==0?style.hide:""].join(" ")}
                onClick={()=>toEdit("school",profile.school)}>
                <span>学校</span>
                <div>{profile.school}</div>
            </div>

            <div className={style.item} 
                onClick={()=>toEdit("major",profile.major)}>
                <span>专业</span>
                <div>{profile.major}</div>
            </div>
            <div className={[style.detail,(profile.hideCode&(1<<4))!==0?style.hide:""].join(" ")} onClick={()=>toEdit("detail",profile.detail)}>
                <span>详细描述</span>
                <div>
                    {profile.detail}
                </div>
            </div>
            <div className={[style.item,style.hide].join(" ")} 
                onClick={()=>toEdit("contact",profile.contact)}>
                <span>联系方式</span>
                <div>{profile.contact}</div>
            </div>
        </div>}
        {edit.show&&<div className={style.edit}>
            <Editors type={edit.key} 
                hideCode={profile.hideCode}
                value={edit.value}
                over={()=>{getProfile();setEdit({...edit,show:false})}}
                close={()=>setEdit({...edit,show:false})} />
        </div>}
    </div>
    

    function toEdit(key,v) {
        let title = key
        let placeholder = ""
        let des = ""
        switch(title){
            case "height":
                title = "身高"
                break;
            case "weight":
                title = "体重"
                break;
            case "city":
                title = "所在城市"
                break;
            case "hometown":
                title = "家乡"
                break;
            case "job":
                title = "职业"
                break;
            case "salary":
                title = "年薪"
                break;
            case "education":
                title = "学历"
                des = "本科在读，本科毕业，本科肄业，研究生在读，研究生毕业，博士在读，博士毕业"
                break;
            case "school":
                title = "学校"
                break;
            case "major":
                title = "专业"
                break;
            case "detail":
                title = "详细描述"
                break;
            default:
                ;
        }
        setEdit({...edit,show:true,key,title,
            ...(v!==undefined&&{value:v}),
            placeholder,
            ...(des&&{des})
        })
    }

    function getProfile() {
        axios.get("/api/square/profile").then(({data})=>{
            if(data.code === 200){
                setProfile(data.data)
            }else if(data.code === 201){
                Alert.alert(data.msg,"fail")
                setProfile(data.data)
            }else{
                Alert.alert(data.msg,"fail")
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}

