import { useEffect, useState } from "react"
import { axios } from "../../../../axios"
import { Link } from "react-router-dom"
import { Pagination } from "antd"



import style from "./archived.posts.module.css"

// 我的话题
export default function ArchivedPosts(props){
    const userId = props.userId
    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1
    })

    useEffect(()=>{
        getMyPosts(1)
        // eslint-disable-next-line
    },[])

    return <div className={style.container}>
        <div>
            {postList.list.map(e=><div key={e.id}>
                    <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                </div>)}

                <Pagination 
                    current={postList.page}
                    total={postList.total}
                    pageSize={18}
                    onChange={(i)=>{
                        getMyPosts(i)
                    }}
                    showTotal={(total) => `共${postList.total}`}
                />
        </div>
    </div>

    function getMyPosts(page) {
        axios.get("/api/topic/post/of-user/archived",{
            params:{
                page:page - 1,
                userId
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostList({
                    ...postList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page
                })
            }
        }).catch(err=>{console.log(err)})
    }
}