import { useState } from "react"


import style from "./css/name.module.css"
import { Input } from "antd"

export default function NameEditor(props){

    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })
    return <div>
        <h4>修改昵称</h4>
        <div className={style.inputContainer}>
            <Input type="text"
                value={value.value}
                onChange={e=>backValue(e.target.value)}
                placeholder="请输入昵称"/>
        </div>
    </div>

    function backValue(v) {
        setValue({...value,value:v})
        props.back({...value,value:v})
    }
}