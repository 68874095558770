import { Button, Input } from "antd"
import { useEffect, useState } from "react"

import style from './css/node.all.module.css'
import { axios } from "../../axios"
import { Link } from "react-router-dom"
import { Search } from "../../icons"

export default function TopicNodes(){

    const [nodes,setNodes] = useState([])
    const [page,setPage] = useState({
        current:1,
        total:0,
        pageSize:40,
        filter:""
    })

    useEffect(()=>{
        getNodes()
        // eslint-disable-next-line
    },[])

    return<div className={style.container}>
        
        <div className={style.search}>
            <div>
                <Input onChange={v=>setPage({...page,filter:v.target.value})} /> 
                <Button onClick={()=>{
                    getNodes(1)
                }}>
                    <Search />
                </Button>
            </div>
        </div>

        <div className={style.nodes}>
            <div className={style.container}>
                {nodes.map((node,key)=><div key={key} className={style.node}>
                    <Link to={'/topic/node/detail/'+node.id}>{node.node}</Link>
                </div>)}
            </div>
        </div>
        
        <div className={style.pager}>
            {page.current!==1&&<span onClick={()=>{
                getNodes(page.current-1)
            }}>上一页</span>}
            {page.current!==(Math.ceil(page.total/page.pageSize))&&<span onClick={()=>{
                getNodes(page.current+1)
            }}>下一页</span>}
        </div>

    </div>

    function getNodes(nextPage) {
        if(nextPage){
            nextPage = nextPage-1
        }else{
            nextPage = page.current-1
        }
        axios.get("/api/topic/node/list",{
            params:{
                page:nextPage,
                filter:page.filter
            }
        }).then(({data})=>{
            if(data.code===200){
                setNodes(data.data.list)
                setPage({...page,total:data.data.total,current:nextPage+1})
            }
        }).catch(err=>{})
    }

}

