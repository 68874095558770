import { useEffect } from "react"
import { axios } from "../../../../axios"
import { useState } from "react"
import { Pagination } from "antd"
import { Link } from "react-router-dom"

import style from "./archived.replies.module.css"
import { localDate2 } from "../../../../tools"


// 我的回复
export default function ArchivedReplies(){

    useEffect(()=>{
        getMyReplies(1)
        // eslint-disable-next-line
    },[])

    const [replies,setReplies] = useState({
        list:[],
        page:1,
        total:0
    })

    return <div>
        {replies.list.map(e=><div key={e.id} className={style.item}>
            <div className={style.postTitle}>
                <p><Link to={'/topic/post/detail/'+e.postId}>{e.post}</Link></p>
            </div>
            <div className={style.replyContent}>
                <p><Link to={'/topic/post/reply/'+e.id}>{e.content}</Link></p>
                <p className={style.btns}>
                    <span className={style.btn}>
                        <span>顶{e.star}</span>
                        <span>踩{e.unstar}</span>
                        <span>回复数{e.replyCount}</span>
                    </span>
                    <span>{localDate2(e.createDatetime)}</span>
                </p>
            </div>
        </div>)}

        <div>
            <Pagination 
                current={replies.page}
                total={replies.total}
                onChange={p=>getMyReplies(p)}
                showTotal={v=>`共${replies.total}`}
            />
        </div>
    </div>


    function getMyReplies(page) {
        axios.get("/api/topic/reply/list/of-user/archived",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                setReplies({...replies,list:data.data.list,total:data.data.total,page})
            }
        }).catch(er=>{console.log(er)})
    }
}