import { Route, Routes } from 'react-router-dom'
import style from './index.module.css'

import PhoneBottom from '../header/phone.bottom'

import Flash from '../flash/'
import FlashDetail from '../flash/detail'
import FlashAdd from '../flash/add.jsx'

import Square from "../suqare"
import Topic from '../topic/index.jsx'
import User from '../user'
import ProfileBase from '../user/profile/index.jsx'
import SettingBase from '../setting/base.jsx'
import UserPointsDetail from '../user/point'
import VIP from '../vip/index.jsx'



export default function Home(props){

    return<div>
        <div className={style.container}>
            <div className={style.content}>
                <Routes>
                    <Route path="/" element={<Flash />} />
                    <Route path="/flash" element={<Flash />} />
                    <Route path='/flash/add' element={<FlashAdd />} />  
                    <Route path="/flash/detail/:id" element={<FlashDetail />} />
                    
                    <Route path="/topic/*" element={<Topic />} />

                    <Route path="/square/*" element={<Square />} />
                    
                    <Route path="/user/points/:userId" element={<UserPointsDetail />} />
                    <Route path='/user/profile/:userId' element={<ProfileBase />} />
                    <Route path='/user/vip' element={<VIP />} />
                    <Route path="/user" element={<User />} />  
                    <Route path="/setting/*" element={<SettingBase />} />
                </Routes>
            </div>
        </div>
        <div className={style.bottom} >
            <PhoneBottom />
        </div>
    </div>
}