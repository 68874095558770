import { useEffect, useState } from "react"
import { axios } from "../../../../axios"


import style from "./index.module.css"
import { useNavigate, useParams } from "react-router-dom"
import { DB} from "../../../../tools"
import Flashs from "./flashs"
import Topics from "./topics"
import ArchivedReplies from "./archived.replies"
import Replies from "./replies"
import ArchivedPosts from "./archived.posts"

export default function UserProfile(){

    const [profile,setProfile] = useState({})
    const [card,setCard] = useState(1)
    const navi = useNavigate()
    const userId = parseInt(useParams().userId)

    useEffect(()=>{
        getUserProfile(userId)
        // eslint-disable-next-line
    },[])

    return<div className={style.container}>
        <div className={style.items}>
            <div className={style.item}>
                <span className={style.placeholder}>帐号</span>
                <span style={{color:"gray"}}>{profile.username}</span>
            </div>
            <div className={style.item}>
                <span className={style.placeholder}>昵称:</span>
                <span>{profile.nickname}</span>
            </div>
            <div className={style.itemNextLine}>
                <div className={style.placeholder}>个性签名:</div>
                <div className={style.content}>
                    <span className={style.text}>
                        {profile.signature}
                    </span>
                </div>
            </div>
            <div className={style.item}>
                <span className={style.placeholder}>性别</span>
                <span>{profile.gender===1?"男":"女"}</span>
            </div>
           
        </div>

        <div className={style.myActivity}>
            <div className={style.cardBtns}>
                <span onClick={()=>setCard(1)} className={card===1?style.active:""}>动态</span>
                <span onClick={()=>setCard(2)} className={card===2?style.active:""}>话题</span>
                <span onClick={()=>setCard(3)} className={card===3?style.active:""}>回复</span>
                <span onClick={()=>setCard(4)} className={card===4?style.active:""}>收藏的话题</span>
                <span onClick={()=>setCard(5)} className={card===5?style.active:""}>收藏的回复</span>
            </div>
            
            {profile.userId&&<>
                {card===1&&<Flashs userId={profile.userId} />}
                {card===2&&<Topics userId={profile.userId}/>}
                {card===3&&<Replies userId={profile.userId}/>}
                {card===4&&<ArchivedPosts userId={profile.userId}/>}
                {card===5&&<ArchivedReplies userId={profile.userId} />}
            </>}
        </div>

    </div>

    function getUserProfile(userId){
        new DB().getByKey("profiles",userId)
        .then(data=>{
            setProfile(data)
        }).catch(err=>{
            axios.get('/api/user/profile',{
                params:{
                    userId
                }
            })
            .then(({data})=>{
                if(data.code===200){
                    setProfile(data.data)
                    new DB().add("profiles",userId,data.data)
                }else{
                    navi("/flash")
                }
            })
        })
    }

}









