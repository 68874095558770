import { useEffect, useState } from "react"
import { axios } from "../../axios"
import { Link } from "react-router-dom"

import {Pagination} from "antd"
import style from "./css//home.module.css"


export default function TopicHome(){

    const [archivedTopic,setArchivedTopic] = useState([])
    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1,
        pageSize:18
    })

    const [selectedList,setSelectedList] = useState({
        list:[],
        total:0,
        page:1,
        pageSize:18
    })

    const [showTab,setShowTab] = useState('new')

    useEffect(()=>{
        getPosts(1)
        getSelectedPost(1)
        getUserArchivedNodes()
        // eslint-disable-next-line
    },[])

    return <div className={style.container}>
        <div>
            <h3>我的节点</h3>
            <div className={style.allNodes}>
                {archivedTopic.map(e=><div key={e.id} className={style.archivedTopic}>
                    <Link to={'/topic/node/detail/'+e.id}>{e.node}</Link>
                </div>)}
            </div>
            <div className={style.toAllTopicBtn}>
                <Link to={'/topic/node/all'}>所有节点</Link>
            </div>
        </div>

        <div className={style.recommend}>
            <div className={style.activeTabs}>
                <span className={showTab==='new'&&style.activeTab} onClick={()=>setShowTab('new')}>最新</span>
                <span className={showTab==='selected'&&style.activeTab} onClick={()=>setShowTab('selected')}>精选</span>
            </div>

            {showTab==='new'&&<div>
                {postList.list.map(e=><div key={e.id}>
                        <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                    </div>)}
                <Pagination 
                    total={postList.total}
                    current={postList.page}
                    pageSize={18}
                    showSizeChanger={false} 
                    onChange={p=>getPosts(p)}
                />
            </div>}
            {showTab==='selected'&&<div>
                {selectedList.list.map(e=><div key={e.id}>
                        <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                    </div>)}
                <Pagination 
                    total={selectedList.total}
                    current={selectedList.page}
                    pageSize={18}
                    showSizeChanger={false} 
                    onChange={p=>getSelectedPost(p)}
                />
            </div>}
   

        </div>
    </div>



    // page index from 1
    function getPosts(page){
        axios.get("/api/topic/post/list",{
            params:{
                page:page - 1
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostList({
                    ...postList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page
                })
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    //获取收藏的post
    function getSelectedPost(page){
        axios.get("/api/topic/post/selected",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                console.log(data)
                setSelectedList({
                    ...selectedList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page
                })
            }
        }).catch(err=>{})
    }

    function getUserArchivedNodes(){
        axios.get("/api/topic/node/archived",{
            params:{
            }
        }).then(({data})=>{
            if(data.code===200){
                setArchivedTopic(data.data)
            }
        }).catch(err=>{})
    }
}