import { useState } from "react"

import style from "./css/salary.module.css"
import { Input } from "antd"
export default function SalaryEditor(props){


    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div>
        <h4>设置年收入</h4>
        <div>
            <Input value={value.value} 
                placeholder='年收入' onChange={e=>{
                    let tmp = {...value,value:e.target.value}
                    setValue(tmp)
                    props.back(tmp)
                }} />
        </div>

        <div className={style.toHide}>
            <span style={{...((value.hide)&&{color:"white",backgroundColor:'black'}),border:"1px solid",cursor:'pointer',userSelect:'none'}} 
                onClick={()=>{
                    let tmp = !value.hide
                    setValue({...value,hide:tmp})
                    props.back({...value,hide:tmp})
                
                }}>匹配后可见</span>
        </div>
    </div>
}