


function ageStr(v){

}

function genderStr(v){
    if(v===0){
        return "男";
    }
    if(v===1){
        return "女";
    }
    return "未设置";
}

function getSexRole(v){
    if(v===0){
        return "0";
    }
    if(v===1){
        return "1";
    }
    if(v===2){
        return "1、0都行"
    }
    if(v===3){
        return "1、0都不喜欢"
    }
    return "未设置";
}


export {genderStr,ageStr,getSexRole}