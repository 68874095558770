import { Button, Select } from "antd"
import { useEffect, useState } from "react"
import { axios } from "../../../axios"
import Alert from "../../my-com/alert"

import style from "./index.module.css"
import Confirm from "../../my-com/confirm"


export default function Share(){

    const [showAdd,setShowAdd] = useState(false)
    const [list,setList] = useState({
        total:0,
        list:[]
    })
    useEffect(()=>{
        getList()
        // eslint-disable-next-line
    },[])
    return <div className={style.container}>

        <div>
            <Button onClick={()=>setShowAdd(true)}>新建分享链接</Button>
        </div>


        <div className={style.shareList}>
            {list.list.map(e=><div id={e.id} key={e.id} className={style.shareItem}>
                <p>
                    <span>访问密钥 {e.passkey}</span>
                </p>
                <p>
                    {e.type===0&& <span>有效期至{e.disableTime}</span>}
                    {e.type===1&& <span>剩余次数 {e.disableTimes}</span>}
                </p>

                <p className={style.itemBtns}>
                    <span onClick={()=>destroy(e.id)}>销毁</span>
                    <span onClick={()=>clip(e.passkey)}>点击复制</span>
                </p>
            </div>)}
        </div>

        {showAdd && <Add refresh = {getList} />}
    </div>

    function destroy(id) {
        Confirm.confirm("确认删除？").then((data)=>{
            console.log(data)
            axios.delete("/api/square/share",{
                params:{
                    id
                }
            }).then(({data})=>{
                if(data.code===200){
                    getList()
                }
            }).catch(err=>console.log(err))
        }).catch(()=>{})

    }

    function clip(key) {
        window.navigator.clipboard.writeText(key).then(()=>{
            Alert.alert("复制成功")
        }).catch(err=>{

        })
    }

    function getList() {
        axios.get("/api/square/share/list",{
            params:{
                page:0
            }
        }).then(({data})=>{
            if(data.code === 200){
                setList({...list,...data.data})
            }
        }).catch(err=>console.log(err))
    }
}


function Add(props){
    const [expireType,setExpireType] = useState(1)
    return <div>

        有效：<Select style={{width:'7em'}}
            onChange={v=>{
                 setExpireType(v)
            }}
            defaultValue={'1'}
             options={[
             {
                value: '1',
                label: '1天',
              },
              {
                value: '2',
                label: '7天',
              },
              {
                value: '3',
                label: '30天',
              },
              {
                value: '4',
                label: '1人|次'
              },
              {
                value: '5',
                label: '7人|次'
              },
              {
                value: '6',
                label: '30人|次'
              }
        ]} />
        <Button onClick={submit}>提交</Button>
    </div>

    function submit() {
        if(!expireType){
            Alert.alert("fuck")
            return
        }
        axios.post("/api/square/share",{
            type:expireType
        }).then(({data})=>{
            if(data.code===200){
                props.refresh()
            }else{
                alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}