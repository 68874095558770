import { Input } from "antd"
import { useState } from "react"

import style from "./css/birthday.module.css"

export default function BirthdayEditor(props){

    const [data,setData] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div className={style.container}>
        <div>
            <Input value={data.value} 
                placeholder="YYYY-MM-DD，如：1990-12-01" onChange={e=>{
                    let tmp = {...data,value:e.target.value}
                    setData(tmp)
                    props.back(tmp)
                }} />
        </div>

        <div className={style.toHide}>
            <span style={{...((data.hide)&&{color:"white",backgroundColor:'black'}),border:"1px solid",cursor:'pointer',userSelect:'none'}} 
                onClick={()=>{
                    let tmp = !data.hide
                    setData({...data,hide:tmp})
                    props.back({...data,hide:tmp})
                }}>匹配后可见</span>
        </div>
    </div>
}