import { Button, Input } from "antd"
import { axios } from "../../../axios"
import { useState } from "react"

import style from "./dislike.module.css"

function Dislike(props){

    document.body.style.overflow='hidden'
    const [msg,setMsg] = useState("")

    return <div className={style.bg}>
        <div>

            <div>
                <p>请认真对待每一次相遇。</p>
                <p>擦肩而过的缘分最是遗憾，请填写不合适的理由再开始下一份期待。</p>
            </div>

            <div>
                <Input.TextArea autoSize={{
                            minRows: 2,
                            maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)}/>
            </div>

            <div>
                <Button onClick={dislike}>提交</Button> <Button onClick={()=>{
                    document.body.style.overflow='auto'
                    props.close(false)
                }}>取消</Button>
            </div>
        </div>
    </div>

    //不合适
    function dislike(){
        axios.post("/api/square/flow/base-profile/dislike",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            if(data.code===200){
                document.body.style.overflow='auto'
                props.close(true)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    
}

function Like(props){
    document.body.style.overflow='hidden'
    const [msg,setMsg] = useState("")
    return <div className={style.bg}>
        <div>

            <div>
                <p>请认真对待每一次爱意。尽可能表达适当的友善真实的你。</p>
            </div>

            <div>
                <Input.TextArea autoSize={{
                    minRows: 2,
                    maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)} />
            </div>

            <div>
                <Button onClick={like}>提交</Button> <Button onClick={()=>{
                    document.body.style.overflow='auto'
                    props.close(false)
                }}>取消</Button>
            </div>
        </div>
    </div>

    //继续沟通
    function like(){
        axios.post("/api/square/flow/base-profile/like",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            if(data.code===200){
                document.body.style.overflow='auto'
                props.close(true)
            }else{
                alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    
}

export {Dislike,Like}