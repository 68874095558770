import style from './index.module.css'
import Confirm from "../my-com/confirm"
import { useNavigate } from "react-router-dom"
import { DB } from '../../tools'

export default function PhoneSetting(props){

    const navi = useNavigate()

    return <div className={style.container}>
        <p style={{textAlign:"center",fontWeight:"bolder"}}>设置</p>

        <div className={style.items}>
            <div>
                <div className={style.item}>隐私设置</div>
                <div className={style.item} onClick={()=>navi("/setting/pwd")}>更改密码</div>
                <div className={style.item}>安全设置</div>

                <div style={{borderBottom:"1px solid gray",margin:"4px 0"}}></div>

                <div style={{borderBottom:"1px solid gray",margin:"4px 0"}}></div>
                <div className={style.item}>
                    <span onClick={logout}>退出登录</span>
                </div>
            </div>
        </div>
    </div>

    function logout() {
        console.log("logout")
        Confirm.confirm("确定要退出吗？").then(()=>{
            localStorage.clear()
            new DB().clear("profiles")
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.href="/"
        }).catch(()=>{
            console.log("no")
        })
    }


    

    
}
















