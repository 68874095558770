import { Route, Routes } from "react-router-dom";
import Setting from "./index.jsx";
import About from "./about";
import PwdChange from "./pwd-change";
import Help from "./help/index.jsx";
import Roules from "./rules/index.jsx";



export default function SettingBase(){
    return <Routes>
        <Route path="/" element={<Setting />} />

        <Route path="pwd" element={<PwdChange />} />
        <Route path="about" element={<About />} />

        <Route path="help" element={<Help />} />
        <Route path="rules" element={<Roules />} />
    </Routes>
}