

import { useState } from "react"

import style from "./css/education.module.css"

export default function EducationEditor(props){

    const [value,setValue] = useState(props.value)
    const list = ['本科在读','本科毕业','本科肄业','研究生在读',
        '研究生毕业','博士在读','博士毕业','其他']
        
    return<div>
        <div>
            <h4>修改学历</h4>
        </div>
        <div>
            {!inList(value)&&<div className={style.active}
                onClick={()=>{
                    setValue(value)
                    props.back(value)
                }}>{value}</div>}

            {list.map(e=><div key={e} className={e===value?style.active:""}
                onClick={()=>{
                    setValue(e)
                    props.back(e)
                }}>
                {e}
            </div>)}
        </div>
    </div>

    function inList(v){
        if(list.includes(v)) return true
        return false
    }


}