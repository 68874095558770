import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { axios } from "../../../axios"

import style from "./index.module.css"


export default function UserPointsDetail(){
    const userId = useParams().userId

    const [points,setPoints] = useState({})

    useEffect(()=>{
        getUserPoints()
        // eslint-disable-next-line
    },[])

    return <div className={style.container}>
        <p>当前等级：{points.level}</p>
        <p>当前经验值:{points.totalPoints}</p>
        <p>距离下一等级需要积分:{points.toNextLevelPoints}</p>
    </div>

    function getUserPoints(){
        axios.get('/api/user/points',{
            params:{
                userId
            }
        }).then(({data})=>{
            if(data.code===200){
                let points = data.data
                setPoints(points)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}