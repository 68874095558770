import { useNavigate, useParams } from "react-router-dom"
import MyProfile from "./my"
import UserProfile from "./user"


export default function ProfileBase(){
    const userId = parseInt(useParams().userId)
    const navi = useNavigate()

    // 路径中没有用户名 直接跳转首页
    if(!userId){
       navi("/") 
    }

    const info = localStorage.getItem("info")
    const logged = info&&(JSON.parse(info).user.id===parseInt(userId))

    return <>
        {logged?<MyProfile userId={userId} />:<UserProfile />}
    </>
}