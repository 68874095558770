

import { useNavigate } from "react-router-dom"
import Btn from "../../my-com/btn"
import style from "./index.module.css"
import { axios } from "../../../axios"
import Alert from "../../my-com/alert"
import { Input } from "antd"
import { useState } from "react"

export default function PwdChange(){
    const [pwd,setPwd] = useState({
        oldPwd:'',
        newPwd:'',
        renewPwd:''
    })

    const navi = useNavigate()
    return <div className={style.container}>
        <p className={style.header}>
            <Btn text="返回" onClick={()=>navi("/setting")} />
            <Btn text="确认" confirm onClick={submit} />
        </p>
        

        <div>
            <Input placeholder="旧密码" onChange={e=>setPwd({...pwd,oldPwd:e.target.value})} />
            <Input placeholder="新密码" onChange={e=>setPwd({...pwd,newPwd:e.target.value})} />
            <Input placeholder="确认新密码" onChange={e=>setPwd({...pwd,renewPwd:e.target.value})} />
        </div>
    </div>


    function submit() {
        if(!pwd.oldPwd || !pwd.newPwd || !pwd.renewPwd){
            Alert.alert("请填写完整")
            return
        }
        if(pwd.newPwd!==pwd.renewPwd){
            Alert.alert("两次密码不一致")
            return
        }

        axios.put('/api/user/pwd',{
            oldPwd:pwd.oldPwd,
            newPwd:pwd.newPwd
        }).then(({data})=>{
            if(data.code===200){
                Alert.alert("修改成功,请重新登录")
                localStorage.clear()
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                window.location.href="/"
            }else{
                Alert.alert(data.msg)
            }
        })
    }
}
