import { Input } from "antd"
import { useState } from "react"

import style from "./css/age.module.css"
export default function AgeEditor(props){

    const [value,setValue] = useState(props.value)

    return<div className={style.container}>
        <div>
            <Input value={value} 
                placeholder="请输入两位纯数字" onChange={e=>{
                    setValue(e.target.value)
                    props.back(e.target.value)
                }} />
        </div>
    </div>
}