import { Button, Input } from "antd";
import { useState } from "react";
import { axios } from "../../axios";
import { useNavigate } from "react-router-dom";
import Alert from "../my-com/alert";
import { Switch } from "antd";

import style from "./add.module.css"


export default function FlashAdd(){

    const [addContent,setAddContent] = useState("")
    const [isPrivate,setIsPrivate] = useState(false)
    const navi = useNavigate()

    return<div className={style.container}>
        <p>
            <Switch checked={isPrivate} checkedChildren="仅自己可见" unCheckedChildren="仅自己可见" onChange={(newV)=>{
                setIsPrivate(newV)
            }} />
        </p>


        <Input.TextArea className={style.textarea} maxLength={500} placeholder="此刻心情，记录一下。"
            value={addContent} onChange={e=>setAddContent(e.target.value)}
            // autoSize={{maxRows:6}} 
            />
        <p>
            <Button onClick={()=>addFlash()}>提交</Button>
        </p>
    </div>

    function addFlash(){
        axios.post("/api/flash",{
            content:addContent,
            isPrivate:isPrivate
        }).then(({data})=>{
            if(data.code===200){
                navi("/flash/detail/"+data.data)
            }else{
                Alert.alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}

