import { useEffect } from "react"
import { axios } from "../../../../axios"
import { useState } from "react"
import { Pagination } from "antd"


import style from "./replies.module.css"

// 我的回复
export default function Replies(props){
    const userId = props.userId
    useEffect(()=>{
        getMyReplies(1)
        // eslint-disable-next-line
    },[])

    const [replies,setReplies] = useState({
        list:[],
        page:1,
        total:0
    })

    return <div>
        {replies.list.map(e=><div key={e.id} className={style.item}>
            <p>{e.content}</p>
        </div>)}

        <div>
            <Pagination 
                current={replies.page}
                total={replies.total}
                pageSize={18}
                onChange={p=>getMyReplies(p)}
                showTotal={v=>`共${replies.total}`}
            />
        </div>
    </div>


    function getMyReplies(page) {
        axios.get("/api/topic/reply/list/of-user",{
            params:{
                page:page-1,
                userId
            }
        }).then(({data})=>{
            if(data.code===200){
                setReplies({...replies,list:data.data.list,total:data.data.total,page})
            }
        }).catch(er=>{console.log(er)})
    }
}