import { Input } from "antd"
import { useState } from "react"

import style from "./css/signature.module.css"

export default function SignatureEditor(props){

    const [value,setValue] = useState(props.value)

    return<div className={style.container}>
        <div>
            <Input value={value} 
                placeholder="个性签名" onChange={e=>{
                    setValue(e.target.value)
                    props.back(e.target.value)
                }} />
        </div>
    </div>
}