import { Input } from "antd"
import { useState } from "react"

import style from "./css/detail.module.css"
export default function DetailEditor(props){

    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div className={style.container}>
        <div>
            <Input.TextArea value={value.value} autoSize
                placeholder="详细介绍" onChange={e=>{
                    setValue({...value,value:e.target.value})
                    props.back({...value,value:e.target.value})
                }} />
        </div>

        <div className={style.toHide}>
            <span style={{...((value.hide)&&{color:"white",backgroundColor:'black'}),border:"1px solid",cursor:'pointer',userSelect:'none'}} 
                onClick={()=>{
                    let tmp = !value.hide
                    setValue({...value,hide:tmp})
                    props.back({...value,hide:tmp})
                
                }}>匹配后可见</span>
        </div>
    </div>
}