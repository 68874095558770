import { useContext, useEffect, useState } from "react"
import { axios } from "../../../../axios"
import { PAIRCONTEXT } from "../context"

import style from "./index.module.css"


function Finished(){

    const PAIRC = useContext(PAIRCONTEXT)
    
    const [records,setRecords] = useState({
        page:1,
        list:[],
        total:0
    })
    useEffect(()=>{
        getProcessingList(1)
        // eslint-disable-next-line
    },[])

    
    return <div className={style.container}>
        {
            records.list.map(e=><div key={e.id} className={style.item} onClick={()=>{
                PAIRC.setFlow({pairSessionId:e.id,status:e.status,showStatus:e.status})
            }}>
                <p>昵称：<span>{e.name}</span></p>
                <p>状态：<span>{statusToCN(e.status)}</span></p>
                <p>时间：<span>{e.createDatetime}</span></p>
                <p>来源：<span>推介</span></p>
            </div>)
        }
    </div>


    function getProcessingList(page){

        axios.get("/api/square/flow/records/finished",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                data = data.data
                setRecords({...records,page,total:data.total,list:data.list})
            }
        }).catch(err=>{})

    }

    function statusToCN(status){
        console.log(status)
        if(status === 'CREATED'){
            return "待确认资料"
        }
        if(status === 'SAY_HELLO'){
            return "资料确认中"
        }
        if(status === 'FACE_PAIRING'){
            return "视频确认中"
        }
        if(status === 'READY_BE_LOVER'){
            return "一周接触"
        }
        if(status === 'NOW_LOVER'){
            return "已确认关系"
        }
        return "fuck"
    }
}

export {Finished}