import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import Home from './component/home';
import Login from './component/login';
import Test from './test';
import { USER } from './context';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <Router />
  </React.Fragment>
);

function Router() {
  const isLogin = localStorage.getItem("info")!==null

  const [userLoged, setUserLoged] = useState(isLogin)

  const user = isLogin? JSON.parse(localStorage.getItem("info")):null;


  return<USER.Provider value={{
      loggedIn:userLoged,
      setLogin:()=>{
        setUserLoged(true);
      },
      logout:()=>{
        setUserLoged(false)
      },
      userInfo:user
  }}>
      <HashRouter>
        <Routes>
          <Route path='/test' element={<Test />} />
          <Route path='/login' element={<Login />} />
          <Route path='/*' element={auth(<Home />)} />
        </Routes>
      </HashRouter>
  </USER.Provider>


// 用户量没到20万 先不要求必须登录
  function auth(ele){
    let permitAnonymous = true;

    if(permitAnonymous){
      return ele;
    }

    if(!userLoged){
      return <Navigate to={"/login"} />
    }
    
    return ele;
  }
}


