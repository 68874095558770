import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./home";
import SquareProfile from "./profile";
import SquareSetting from "./setting";
import PreviewProfile from "./profile/preview";
import FindYou from "./findyou";
import PairedRecord from "./paired";
import Share from "./share";


export default function Square(){

    return<>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<SquareProfile />} />
            <Route path="/profile/preview" element={<PreviewProfile />} />
            <Route path="/setting" element={<SquareSetting />} />
            <Route path="/find" element={<FindYou />} />
            <Route path="/paired" element={<PairedRecord />} />
            <Route path="/share" element={<Share />} />
        </Routes>
    </>
}