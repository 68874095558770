
import style from "./index.module.css"
import { useEffect, useState } from "react"
import { InProgress } from "./inprogress"
import { Finished } from "./finished"
import { PAIRCONTEXT } from "./context"
import SquareFlow from "./flow"
import { useLocation, useNavigate } from "react-router-dom"
import { axios } from "../../../axios"

// 打招呼记录  
export default function PairedRecord(){

    const location = useLocation();
    const navi = useNavigate()
    const id = new URLSearchParams(location.search).get('id')

    const [tab,setTab] = useState("inProgress")
    const [flow,setFlow] = useState({
        show:0//0 啥也不展示 1列表 2记录
    })

    useEffect(()=>{
        if(id){
            init()
        }else{
            setFlow({
                show:1,
                pairSessionId:-1,
                status:"",
                showStatus:""
            })
        }
     // eslint-disable-next-line
    },[])
    function init(){
        axios.get("/api/square/flow/record-by-id",{
            params:{
                id:id
            }
        }).then(({data})=>{
            console.log(data)

            if(data.code===200){
                data = data.data
                setFlow({
                    show:2,
                    pairSessionId:data.id,
                    status:data.status,
                    showStatus:data.status
                })
            }
        }).catch(err=>{})
    }



    return <div className={style.container}>

        <PAIRCONTEXT.Provider value={{
            flow,
            setFlow:(v)=>{
                setFlow({
                    ...flow,
                    ...v, 
                    show:2
                })
            },
            setSessionId:(id)=>{
                setFlow({
                    ...flow,
                    pairSessionId:id
                })
            },
            canToNext:(v)=>{
                const stats = ["CREATED","SAY_HELLO","FACE_PAIRING","READY_BE_LOVER","NOW_LOVER"]
                let statusIndex = stats.indexOf(flow.status)
                let showStatusIndex = stats.indexOf(v)
                if(showStatusIndex<statusIndex){
                    return true
                }
                return false
            }
        }}> 


            {flow.show===1&&<div>
                <div className={style.tabs}>
                    <span className={tab==="inProgress"?style.active:""}
                        onClick={()=>setTab("inProgress")}>正在进行</span> 
                    <span className={tab==="finished"?style.active:""}
                        onClick={()=>setTab("finished")}>已结束</span>
                </div>
                {tab==="inProgress"&& <InProgress />}
                {tab==="finished"&& <Finished />}
            </div>}
            
            {flow.show===2&&<div>
                <div>
                    <span onClick={()=>{
                        if(!id){
                            setFlow({...flow,show:1})
                        }else{
                            setFlow({...flow,show:1})
                            navi("/square/paired")
                        }
                    }}>CLOSE</span>
                </div>
                <SquareFlow />
            </div>}

        </PAIRCONTEXT.Provider>

    </div>

}