import { useContext, useEffect, useState } from "react"
import { PAIRCONTEXT } from "../../context"

import style from "./index.module.css"
import { Dislike, Like } from "./like"
import { axios } from "../../../../../axios"

export default function AfterAWeek(){
    const PAIRC = useContext(PAIRCONTEXT)
    const sessionId = PAIRC.flow.pairSessionId

    const [show,setShow] = useState({
        show:false,
        type:'',//like or dislike,
    })
    const [confirmRecord,setConfirmRecord] = useState({})

    useEffect(()=>{
        getConfirmRecord()
        // eslint-disable-next-line
    },[])




    return <div>

        <div>
            <h2 style={{textAlign:"center"}}>一周接触</h2>
        </div>

        <div>
            你们决定开始试相处的时间是：{1234}，还有 X 天 X小时你们可以决定成为伴侣。
        </div>

        <div>
            {confirmRecord.myAfterWeek && <div>
                <p>我的决定: {confirmRecord.myAfterWeek.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <p>{confirmRecord.myAfterWeek.comment}</p>
            </div>}

            {confirmRecord.hisAfterWeek && <div>
                <p>他的决定: {confirmRecord.hisAfterWeek.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <p>{confirmRecord.hisAfterWeek.comment}</p>
            </div>}
        </div>


        {!confirmRecord.myAfterWeek&&<div>
            <p>是否愿意成为结为伴侣?</p>
            <div className={style.confirmBtns}>
                <div>
                    <span onClick={()=>{
                        setShow({show:true,type:"like"})
                    }}>确认</span>
                    <span onClick={()=>{
                        setShow({show:true,type:"dislike"})
                    }}>放弃</span>
                </div>
            </div>
        </div>}



        {show.show&&<div>
            {show.type==='like'&&<Like sessionId={sessionId} close={(submitted)=>{
                setShow({show:false,type:''})
                if(submitted){
                    window.location.reload()
                }
            }} />}   

            {show.type==='dislike' && <Dislike sessionId={sessionId} close={(submitted)=>{
                setShow({show:false,type:''})
                if(submitted){
                    window.location.reload()
                }
            }} />}
        </div>}


    

        <div className={style.preOrNext}>
            <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"FACE_PAIRING"})}>&lt;-</span>
            {PAIRC.canToNext('READY_BE_LOVER') && 
                <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"NOW_LOVER"})}>-&gt;</span>}
        </div>

    </div>


    function getConfirmRecord(){
        axios.get("/api/square/flow/after-week",{
            params:{
                sessionId
            }
        }).then(({data})=>{
            if(data.code===200){
                setConfirmRecord(data.data)
            }
        }).catch(err=>{console.log(err)})
    }
}