import { useEffect, useState } from "react"
import { axios } from "../../../../axios"
import { Link } from "react-router-dom"
import { Pagination } from "antd"

import style from "./topics.module.css"
import { localDate } from "../../../../tools"



// 我的话题
export default function Topics(props){
    const userId = props.userId
    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1,
    })

    useEffect(()=>{
        getMyPosts(1)
        // eslint-disable-next-line
    },[])

    return <div className={style.container}>
        <div>
            {postList.list.map(e=><div key={e.id} className={style.topic}>
                    <p><Link to={'/topic/post/detail/'+e.id}>{e.title}</Link></p>
                    <div>
                        <span className={style.ss}>
                            <span>回复数{e.replyCount}</span>
                            {e.stared?<span>顶{e.star}</span>:<span className={style.un}>顶{e.star}</span>}
                            {e.unstared?<span>踩{e.unstar}</span>:<span className={style.un}>踩{e.unstar}</span>}
                            <span className={style.date}>{localDate(e.createDatetime)}</span>
                        </span>
                        
                    </div>
                </div>)}

                <Pagination 
                    current={postList.page}
                    total={postList.total}
                    pageSize={18}
                    onChange={(i)=>{
                        getMyPosts(i)
                    }}
                    showTotal={(total) => `共${postList.total}`}
                />
        </div>
    </div>

    function getMyPosts(page) {
        axios.get("/api/topic/post/of-user",{
            params:{
                page:page - 1,
                userId
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostList({
                    ...postList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page
                })
            }
        }).catch(err=>{console.log(err)})
    }
}