import { useNavigate, useParams } from "react-router-dom"
import { axios } from "../../axios"
import { useEffect, useState } from "react"

import style from './detail.module.css'
import { Button, Input } from "antd"
import { checkLogin, localDate, share, toFold } from "../../tools"
import Alert from "../my-com/alert"


export default function FlashDetail(){

    const id = useParams().id
    useEffect(()=>{
        getDetail()
        // eslint-disable-next-line
    },[])
    const navi = useNavigate()

    const [detail,setDetail] = useState({
        user:{},
        content:""
    })
    const [comments,setComments] = useState({
        list:[],
        total:0,
        currentPage:0
    })
    const [showAddComment,setShowAddComment] = useState(false)
    
    const [commentContent,setCommentContent] = useState("")

    const [showFullContent,setShowFullContent] = useState(false)
    //评论只能展开一个
    const [showFullCommentId,setShowFullCommentId] = useState()
    return<div className={style.container}>

        <div className={detail.state===0?style.contentContainer:style.privateContentContainer}>
            <div className={style.itemHeader}>
                <span className={style.user} onClick={()=>navi("/user/profile/"+detail.user.id)}>{getNicknameOrUsername(detail.user)}</span>
                <span className={style.date}>{localDate(detail.createDatetime)}</span>
            </div>

            <div className={style.content}>
                <p className={toFold(detail.content)&&!showFullContent?style.contentTextHide:style.contentText}>{detail.content}</p>
                {
                toFold(detail.content)&&<>{showFullContent?
                <p className={style.folder} onClick={()=>setShowFullContent(false)}>
                    <span style={{fontSize:"0.9em",color:"#ababab"}}>^收起</span>
                </p>:<p className={style.folder} onClick={()=>setShowFullContent(true)}>
                    <span style={{fontSize:"0.9em",color:"#ababab"}}>...查看全部</span>
                </p>}</>
                }
            </div>

            <p className={style.footer}>
                <span className={style.comment}>回复{detail.commentCount}</span>
                <span className={style.btn} onClick={starFlash}>顶{detail.up}</span>
                <span className={style.btn} onClick={unstarFlash}>踩{detail.down}</span>
                <span style={{fontSize: "0.8em",backgroundColor:" #dff0ff",borderRadius:"2px"}} onClick={()=>share("/#/flash/detail/"+detail.id)}>分享</span>
            </p>
        </div>

        <p>
            {!showAddComment&&<Button onClick={()=>setShowAddComment(true)}>添加回复</Button>}
            {showAddComment&&<Button onClick={submitAddComment}>提交</Button>}
            <span> </span>
            {showAddComment&&<Button onClick={()=>setShowAddComment(false)}>取消</Button>}
        </p>

        <div>
            {showAddComment&&
            <Input.TextArea rows={4} placeholder="请输入回复内容"
                onChange={e=>setCommentContent(e.target.value)} autoSize />}
        </div>


        <div className={style.commentsContainer}>
            {comments.list.map(e=><div key={e.id} className={style.commentDetail}>
                <div>
                    <div className={style.commentHeader}>
                        <span className={style.user} onClick={()=>navi("/user/profile/"+e.user.id)}>{getNicknameOrUsername(e.user)}</span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </div>

                    <p className={toFold(e.content)&&showFullCommentId!==e.id?style.contentHide:style.content}>{e.content}</p>
                    {toFold(e.content)&&<>{showFullCommentId===e.id?
                    <p className={style.folder} onClick={()=>setShowFullCommentId(null)}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>^收起</span>   
                    </p>:<p className={style.folder} onClick={()=>setShowFullCommentId(e.id)}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>...查看全部</span>
                    </p>}</>}
                </div>
                <p className={style.footer}>
                    <span className={style.btn} onClick={()=>starComment(e)}>顶{e.up}</span>
                    <span className={style.btn} onClick={()=>unstarComment(e)}>踩{e.down}</span>
                </p>
            </div>)}
        </div>
    </div>


    function submitAddComment() {
        if(!commentContent){
            alert("请输入回复内容")
            return
        }
        axios.post("/api/flash/comment",{
            flashId:id,
            content:commentContent
        }).then(({data})=>{
            if(data.code===200){
                getComments()
                setShowAddComment(false)
                setCommentContent("")
            }
        })
    }
    function getDetail() {
        axios.get("/api/flash/detail",{
            params:{
                id
            }
        }).then(({data})=>{
            if(data.code===200){
                setDetail(data.data)
                getComments()
            }else{
                Alert.alert(data.msg,"fail")
                window.setTimeout(()=>navi("/flash"),1000)
            }
        })
    }

    function getComments(){
        axios.get("/api/flash/comment",{
            params:{
                flashId:id,
                page:comments.currentPage
            }
        }).then(({data})=>{
            if(data.code===200){
                setComments({
                    ...comments,
                    list:data.data.list,
                    total:data.data.total
                })
            }
        })
    }

    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }


    function starFlash(){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(detail.stared){
            return
        }
        axios.post("/api/flash/star",{
            flashId:id
        }).then(({data})=>{
            if(data.code===200){
                setDetail({
                    ...detail,
                    stared:true,
                    up:detail.up+1
                })
            }
        })

    }
    function unstarFlash(){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(detail.unstared){
            return
        }
        axios.post("/api/flash/unstar",{
            flashId:id
        }).then(({data})=>{
            if(data.code===200){
                setDetail({
                    ...detail,
                    unstared:true,
                    down:detail.down+1
                })
            }
        })
    }

    function starComment(comment){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(comment.stared){
            return
        }
        axios.post("/api/flash/comment/star",{
            commentId:comment.id
        }).then(({data})=>{
            if(data.code===200){
                let tmpComments = [...comments.list]
                tmpComments.forEach(e=>{
                    if(e.id===comment.id){
                        e.up++
                        return
                    }
                })
                setComments({
                    ...comments,
                    list:tmpComments
                })
            }
        })
    }
    function unstarComment(comment){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(comment.unstared){
            return
        }
        axios.post("/api/flash/comment/unstar",{
            commentId:comment.id
        }).then(({data})=>{
            if(data.code===200){
                let tmpComments = [...comments.list]
                tmpComments.forEach(e=>{
                    if(e.id===comment.id){
                        e.down++
                        return
                    }
                })
                setComments({
                    ...comments,
                    list:tmpComments
                })
            }
        })
    }

    
}