import { Button } from "antd";
import Share from "./component/suqare/share";
import Confirm from "./component/my-com/confirm";
export default function Test(){
    return <div>
        test
        <Button onClick={fuck}>fuck</Button>
        <Share />
    </div>

    function fuck() {
        Confirm.confirm("fuck").then(()=>{
            alert("fuck")
        }).catch(()=>{})
    }
}



