import { Button, Input } from "antd"
import { useState } from "react"

import style from "./like.module.css"
import { axios } from "../../../../../axios"

function Dislike(props){
    document.body.style.overflow='hidden'
    const [msg,setMsg] = useState("")
    return <div className={style.bg}>
        <div>
            <div>
                <p>请认真对待每一次相遇。</p>
                <p>如果有可能的话还是尽量线下见一面吧，很多人在摄像头下的状态远不如实际上眼睛看到的效果。</p>
                <p>如果实在不是你喜欢的类型，点击提交后将永远不会再遇到对方。</p>
                <p>请在点击提交后删除对方的其他联系方式。</p>
            </div>

            <div>
                <Input.TextArea autoSize={{
                            minRows: 2,
                            maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)}/>
            </div>

            <div>
                <Button onClick={dislike}>提交</Button> <Button onClick={()=>{
                    document.body.style.overflow='auto'
                    props.close(false)
                }}>取消</Button>
            </div>
        </div>
    </div>

    //不合适
    function dislike(){
        axios.post("/api/square/flow/face/like",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            console.log(data)
        }).catch(err=>console.log(err))
    }
    
}

function Like(props){
    document.body.style.overflow='hidden'
    const [msg,setMsg] = useState("")
    return <div className={style.bg}>
        <div>
            <div>
                <p>请认真对待每一次爱意。尽可能表达适当的友善真实的你。</p>
                <p>视频中人的状态和现实中往往差距很大。如果你们都满意的话将进入下一步，一周试相处阶段。</p>
                <p></p>
            </div>

            <div>
                <Input.TextArea autoSize={{
                    minRows: 2,
                    maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)} />
            </div>

            <div>
                <Button onClick={like}>提交</Button> <Button onClick={()=>{
                    document.body.style.overflow='auto'
                    props.close(false)
                }}>取消</Button>
            </div>
        </div>
    </div>

    //继续沟通
    function like(){
        axios.post("/api/square/flow/face/like",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            if(data.code===200){
                document.body.style.overflow='auto'
                props.close(true)
            }else{
                alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    
    
}

export {Dislike,Like}