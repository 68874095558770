import { useState } from "react"

import style from "./css/sexrole.module.css"

export default function SexRoleEditor(props){

    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div>

        <div>
            <h4>性角色</h4>
        </div>
        <div>
            <div className={value.value===0?style.active:''}
                onClick={()=>choice(0)}>0</div>
            <div className={value.value===1?style.active:''} 
                onClick={()=>choice(1)}>1</div>
            <div className={value.value===2?style.active:''} 
                onClick={()=>choice(2)}>1、0都行</div>
            <div className={value.value===3?style.active:''}
                onClick={()=>choice(3)}>1、0都不喜欢</div>
        </div>

        <div className={style.toHide}>
            <span style={{...((value.hide)&&{color:"white",backgroundColor:'black'}),border:"1px solid",cursor:'pointer',userSelect:'none'}} 
                onClick={()=>{
                    let tmp = !value.hide
                    setValue({...value,hide:tmp})
                    props.back({...value,hide:tmp})
                
                }}>匹配后可见</span>
        </div>
    </div>

    function choice(v){
        let tmp = {...value,value:v}
        setValue(tmp)
        props.back(tmp)
    }
}