
import style from "./index.module.css"

export default function Roules(){


    return <div className={style.container}>
        <h2>社区规则 必读 <span className={style.bg}>请勿</span>：</h2>

        <div>
            <p>
                1. <span className={style.bold}>人身攻击</span>  包括辱骂、诽谤、歧视、侮辱、挑衅、恐吓、威胁、人肉搜索、暴露隐私等。
            </p>
            <p>
                2. 严重骚扰  人肉他人资料，骚扰他人通讯工具，骚扰他人家庭成员，骚扰他人工作单位，骚扰他人现实生活等。
            </p>
            <p>
                3. <sapn className={style.bold}>发布广告</sapn>。
            </p>
            <p>
                4. 发布<span className={style.bold}>色情内容</span> 色情内容请到色情社区比如XXX。
            </p>
            <p>
                5. 发布<sapn className={style.bold}>违法内容</sapn>。
            </p>
            <p>
                6. 发布<sapn className={style.bold}>政治敏感内容</sapn> 包括但不限于国家体制、政策、领导人。
            </p>
            <p>
                7. 发布恶意虚假信息 。
            </p>
            <p>
                8. 发布大量重复、无意义的<span className={style.bold}>垃圾信息</span>。
            </p>
        </div>


        <div>
            这里不讨论言论自由的边界，任何地方都有一套合适的规则才能持续的发展成长。规则以外皆可为，如果对以上规则有问题欢迎到‘社区建设’话题下进行讨论。
        </div>


        <div>
            <div>
                <p>一 动态篇</p>
                <div>
                     与朋友圈、说说不同的是，这里不存在任何好友关系，也不提供关注和粉丝功能。
                     发布的动态支持私有模式，此种模式下仅发布者可见。可以用来作为生活日志，笔记等。因为私有模式仅发布者可见，所以动态内容不必遵守任何规则。

                     公开发布的话，将全站可见。请遵守以上我们的社区规范。
                </div>
            </div>

            <div>
                <p>二 话题篇</p>
                <div>
                    话题类似与贴吧的帖子，也类似于知乎的提问。不同的是我们以以下规则作为约束，来维持有逻辑、有价值并且友好的讨论环境。
                </div>
            </div>

        </div>
    </div>
}