


import style from "./index.module.css"

export default function Help(){
    return (
        <div className={style.container}>
            <h1>Help</h1>

            <p>
                因为开发人员精力有限，目前仅维护PWA应用。
            </p>
            <p>
                因为PWA应用维护简单，不需要下载安装，只需要在浏览器中打开即可使用。
            </p>
            <p>绝对隐私，不需要安装App，无法获取手机内容。</p>
            <p>
                iPhone用户使用技巧：使用Safari浏览器打开，然后点击底部的分享按钮，选择添加到主屏幕即可。
            </p>
            <p>
                Android用户请用Chrome浏览器或者自带浏览器打开，然后点击右上角的菜单按钮，选择添加到主屏幕即可。
            </p>

            <h3>忘记密码</h3>
            请发送帐号、帐号填写的邮箱 发送到 xxx@xxx.com
        </div>
    )
}