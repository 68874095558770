import React from "react";
import ReactDOM from 'react-dom/client';

import style from './index.module.css'
import Btn from "../btn";
export default class Confirm extends React.Component{

    render(){
        return <div className={style.bg}>
            <div className={style.container}>
                <div className={style.msg}>
                    <p>{this.props.msg}</p>
                </div>

                <div className={style.btns}>
                    <p>
                        <Btn onClick={this.props.success} text="Yes" />
                        <Btn onClick={this.props.fail} text="No" confirm />
                    </p>
                </div>

            </div>

        </div>
    }


    static confirm(msg){
        let node = document.createElement("div")
        document.body.appendChild(node)
        let tmpRoot = ReactDOM.createRoot(node)

        let resolveTmp,rejectTmp;
        let promise = new Promise((resolve,reject)=>{
            resolveTmp=resolve;
            rejectTmp=reject;
        })
        tmpRoot.render(<Confirm msg={msg} success={()=>{
            tmpRoot.unmount()
            document.body.removeChild(node)
            resolveTmp("yes")
        }} fail={()=>{
            tmpRoot.unmount()
            document.body.removeChild(node)
            rejectTmp("no")
        }}/>)
        return promise;
    }
}






